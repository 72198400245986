.markerContainer {
  position: relative;
  grid-row: 1;
  grid-column: 1;
  z-index: 3;
}

.marker {
  position: absolute;
  border: 1px solid yellow;
  outline: 1px solid #000;
  width: 8px;
  height: 8px;
}